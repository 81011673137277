import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";

let messaging;

if (isSupported()) {
  const firebaseConfig = {
    apiKey: "AIzaSyBn6VtdBk8PiUg8AVGUhOvOn7syENjX5gQ",
    authDomain: "foodomaa-demo-2af64.firebaseapp.com",
    projectId: "foodomaa-demo-2af64",
    storageBucket: "foodomaa-demo-2af64.firebasestorage.app",
    messagingSenderId: "467039778784",
    appId: "1:467039778784:web:25993de4a7a4ec2cd21d85",
  };

  const initializedFirebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(initializedFirebaseApp);
}

export default messaging;
